import React from 'react'
import Layout from '../components/layout'
import { graphql, StaticQuery } from 'gatsby'
import Flickity from 'react-flickity-component'
import Footer from '../components/footer'

const flickityOptions = {
	initialIndex: 0,
	autoPlay: 4500,
	pauseAutoPlayOnHover: true,
	accessibility: true,
	wrapAround: true,
}

const thumbnailFlickityOptions = {
	wrapAround: true,
	asNavFor: '.carousel.carousel-main',
	contain: true,
	pageDots: false,
}

export default ({ pageContext }) => (
	<StaticQuery
		query={graphql`
			{
				allWordpressPage(filter: { title: { eq: "Real Estate" } }) {
					edges {
						node {
							acf {
								gallery {
									source_url
								}
							}
						}
					}
				}
			}
		`}
		render={(props) => (
			<Layout>
				<div className='page-title'>
					<h1 dangerouslySetInnerHTML={{ __html: pageContext.title }} />
				</div>
				<div className='container desktop-gallery'>
					<Flickity
						id='main-flickity'
						className='carousel carousel-main spacing'
						data-flickity='{"pageDots": false}'
						elementType={'section'}
						options={flickityOptions}>
						{props.allWordpressPage.edges[0].node.acf.gallery.map((image) => (
							<div className='carousel-cell'>
								<img src={image.source_url} alt='Hero' />
							</div>
						))}
					</Flickity>
					<Flickity
						className='carousel carousel-nav'
						data-flickity='{ "contain": true, "pageDots": false }'
						elementType={'section'}
						options={thumbnailFlickityOptions}
						disableImagesLoaded={false}>
						{props.allWordpressPage.edges[0].node.acf.gallery.map((image) => (
							<div className='carousel-cell'>
								<img src={image.source_url} alt='Hero' />
							</div>
						))}
					</Flickity>
				</div>

				<div className='mobile-gallery'>
					{props.allWordpressPage.edges[0].node.acf.gallery.map((image) => (
						<div className='mobile-images'>
							<img src={image.source_url} alt='Mobile Gallery' />
						</div>
					))}
				</div>
				<Footer />
			</Layout>
		)}
	/>
)
